@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.secondScreenContainer {
  width: 100%;
  position: relative;
  height: size-rem(1150px);
  overflow: hidden;
  margin-top: size-rem(120px);
  @include vp--740 {
    height: size-rem(1040px, true);
  }
}

.secondScreenTitle {
  width: size-rem(882px);
  height: fit-content;
  position: absolute;
  left: size-rem(400px);
  top: size-rem(20px);
  margin-top: 0;
  z-index: 1;
  @include vp--740 {
    width: size-rem(366px, true);
    left: 5%;
    top: size-rem(20px, true);
  }
}

.secondScreenTitle p {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(85px);
  line-height: 95%;
  letter-spacing: -0.02em;
  color: $clr-black;
  margin-bottom: 0;
  margin-top: 0;
  z-index: 1;
  @include vp--740 {
    font-size: size-rem(40px, true);
  }
}

.secondScreenTitle :last-child {
  position: relative;
  left: size-rem(400px);
  @include vp--740 {
    left: size-rem(0px);
  }
}

.secondScreenText {
  position: absolute;
  bottom: size-rem(200px);
  left: size-rem(180px);
  color: $clr-black;
  font-weight: 300;
  font-size: size-rem(32px);
  line-height: 100%;
  font-family: $main-font-family;
}

.secondScreenText p {
  line-height: 110%;
  margin-bottom: size-rem(0px);
  margin-top: size-rem(0px);
}

.secondScreenText :last-child {
  margin-left: size-rem(20px);
}

.secondScreenDescription {
  width: size-rem(310px);
  height: size-rem(350px);
  font-family: $primary-font-family;
  position: absolute;
  font-size: size-rem(18px);
  line-height: 140%;
  letter-spacing: -0.02em;
  color: $clr-dark-grey;
  top: size-rem(506px);
  left: size-rem(1195px);

  @include vp--740 {
    height: size-rem(98px, true);
    font-size: size-rem(18px, true);
    top: size-rem(590px, true);
    left: 5%;
    width: 90%;
  }

  @include vp--tablet {
    top: size-rem(590px);
  }
}

.secondScreenImgOne {
  position: absolute;
  top: size-rem(60px);
  left: size-rem(-10px);
  width: size-rem(272px);
}

.secondScreenImgTwo {
  position: absolute;
  top: size-rem(420px);
  left: size-rem(400px);
  width: size-rem(220px);
  height: size-rem(276px);
  z-index: 1;
}
.secondScreenImgThree {
  position: absolute;
  top: size-rem(150px);
  left: size-rem(516px);
  width: size-rem(591px);
  @include vp--740 {
    top: size-rem(130px, true);
    left: 5%;
    width: 90%;
  }
}

.secondScreenImgFour {
  position: absolute;
  will-change: transform;
  top: size-rem(140px);
  left: size-rem(1195px);
  width: size-rem(240px);
  @include vp--740 {
    top: size-rem(870px, true);
    left: 5%;
    width: size-rem(175px, true);
  }
}
.secondScreenImgFive {
  position: absolute;
  top: size-rem(185px);
  right: size-rem(0px);
  right: size-rem(-60px);
  max-width: size-rem(120px);
  max-width: size-rem(186px);
  width: size-rem(188px);
  max-height: size-rem(277px);
  overflow: hidden;
  @include vp--740 {
    top: size-rem(800px, true);
    right: 5%;
    width: size-rem(175px, true);
    max-width: size-rem(175px, true);
    max-height: size-rem(277px, true);
    overflow: hidden;
  }

  @include vp--tablet {
    max-width: size-rem(135px);
    max-height: size-rem(277px);
  }
}

.videoButton {
  position: absolute;
  bottom: size-rem(91px);
  left: 50%;
  transform: translate(-50%);

  @include vp--tablet {
    bottom: size-rem(35px, false, false, true);
  }

  @include vp--740 {
    bottom: size-rem(35px, true);
  }
}
