@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.sixthScreenContainer {
  width: 100%;
  min-height: size-rem(1000px);
  height: fit-content;
  overflow: hidden;
  position: relative;
  @include vp--740 {
    height: 100vh;
    min-height: 100vh;
  }
}

.sixthScreenContainerImg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.sixthScreenTitle {
  width: 100%;
  margin-top: size-rem(120px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(85px);
  line-height: 95%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-white;
  @include vp--740 {
    margin-top: size-rem(60px, true);
    font-size: size-rem(42px, true);
  }
}

.sixthScreenBox {
  margin: auto;
  margin-top: size-rem(75px);
  @include flex-row;
  width: size-rem(500px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include vp--740 {
    margin-top: size-rem(60px, true);
    @include flex-column;
    width: 100%;
  }
}

.sixthScreenIcon {
  width: size-rem(74px);
  height: size-rem(74px);
  border: 1px solid $clr-white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  &:hover {
    background-color: $clr-white;
    transition: all 0.5s ease;
  }
  @include vp--740 {
    width: size-rem(74px, true);
    height: size-rem(74px, true);
  }
}

.svgClass {
  width: size-rem(24px);
  height: size-rem(24px);
  z-index: 99999;
  @include vp--740 {
    width: size-rem(24px, true);
    height: size-rem(24px, true);
  }
}

.sixthScreenText {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 90%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-white;
  text-transform: uppercase;
  margin-left: size-rem(16px);
  @include vp--740 {
    font-size: size-rem(18px, true);
    margin-top: size-rem(30px, true);
    margin-left: initial;
  }
}
