@import '../variables.scss';

@mixin text {
  font: {
    family: $main-font-family;
    style: normal;
    weight: 350;
  }
  color: $clr-dark-black;
}

@mixin primary-text {
  font: {
    family: $primary-font-family;
    style: normal;
    weight: 400;
  }
  color: $clr-dark-black;
}

@mixin p-12 {
  font-family: $main-font-family;
  font-size: size-rem(12px);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;

  @include vp--740 {
    font-size: size-rem(12px, true);
  }
}

@mixin p-14 {
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;

  @include vp--740 {
    font-size: size-rem(14px, true);
  }
}

@mixin p-16 {
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;

  @include vp--740 {
    font-size: size-rem(16px, true);
  }
}

@mixin p-18 {
  font-family: $main-font-family;
  font-size: size-rem(18px);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;

  @include vp--740 {
    font-size: size-rem(16px, true);
    line-height: 120%;
  }
}

@mixin p-20 {
  font-family: $main-font-family;
  font-size: size-rem(20px);
  font-style: normal;
  font-weight: 350;
  line-height: 130%;
}

@mixin p-24 {
  font-family: $main-font-family;
  font-size: size-rem(24px);
  font-style: normal;
  font-weight: 350;
  line-height: 130%;

  @include vp--740 {
    font-size: size-rem(20px, true);
  }
}

@mixin p-32 {
  font-family: $main-font-family;
  font-size: size-rem(32px);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;

  @include vp--740 {
    font-size: size-rem(18px, true);
    line-height: 140%;
  }
}

@mixin p-85 {
  font-family: $main-font-family;
  font-size: size-rem(85px);
  font-style: normal;
  font-weight: 350;
  line-height: 95%; /* 80.75px */
  letter-spacing: -1.7px;

  @include vp--740 {
    font-size: size-rem(40px, true);
    line-height: 110%;
  }
}
