@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.promo {
  position: absolute;
  z-index: 3000000;
  bottom: size-rem(30px);
  background-color: $clr-white;
  display: flex;
  align-items: center;
  padding: size-rem(10px) size-rem(20px) size-rem(10px) size-rem(10px);
  border-radius: size-rem(100px);
  left: 50%;
  transform: translateX(-50%);
  transition: all ease 0.13s;
  width: size-rem(360px);
  justify-content: space-between;

  @include vp--740 {
    bottom: size-rem(28px, true);
    border-radius: size-rem(100px, true);
    padding: size-rem(10px, true) size-rem(20px, true) size-rem(10px, true) size-rem(10px, true);
    width: size-rem(360px, true);
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &:hover {
    & > span:last-child > svg > path {
      stroke: $clr-green;
      transition: all ease 0.13s;
      @include vp--740 {
        stroke: $clr-dark-black;
      }
    }

    & div > p {
      color: $clr-green;
      transition: all ease 0.13s;
      @include vp--740 {
        color: $clr-dark-black;
      }
    }
  }

  &__icon {
    width: size-rem(52px);
    height: size-rem(52px);
    position: relative;
    margin-right: size-rem(20px);
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include vp--740 {
      width: size-rem(52px, true);
      height: size-rem(52px, true);
      margin-right: size-rem(20px, true);
    }

    & > img {
      width: 100%;
      height: 100%;
      transition: all ease 0.13s;
    }
  }
  &__title > p {
    margin-right: size-rem(14px);
    font-family: $primary-font-family;
    font-size: size-rem(16px);
    font-weight: 400;
    line-height: size-rem(19px);
    letter-spacing: 0em;
    text-align: left;
    color: $clr-dark-black;
    text-wrap: nowrap;
    transition: all ease 0.13s;
    white-space: nowrap;

    @include vp--740 {
      margin-right: size-rem(14px, true);
      font-size: size-rem(16px, true);
      line-height: size-rem(19px, true);
    }
  }
  &__arrow {
    width: size-rem(24px);
    height: size-rem(24px);
    position: relative;
    transition: all ease 0.13s;

    @include vp--740 {
      width: size-rem(24px, true);
      height: size-rem(24px, true);
    }
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
}

.cardWrapper {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
}
