@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
.textStyle_one,
.textStyle_two,
.textStyle_three,
.textStyle_one_two {
  font-family: $main-font-family;
  font-size: size-rem(128px);
  font-weight: 400;
  color: $clr-white;
  line-height: 75%;
}

.textStyleMob_one,
.textStyleMob_two,
.textStyleMob_three,
.textStyleMob_four {
  font-family: $main-font-family;
  font-size: size-rem(57px, true);
  font-weight: 400;
  color: $clr-white;
  line-height: 99%;
  transition: all 2s ease;
}

.textStyle_one {
  width: size-rem(1050px);
  height: size-rem(125px);
  animation: text-clip-one 2s 0.1s cubic-bezier(0.5, 0, 0.1, 1) both;
  animation-iteration-count: 1;
}

.textStyle_one_two {
  width: size-rem(380px);
  height: size-rem(125px);
  margin-left: size-rem(700px);
  animation: text-clip-one-two 2s 0.1s cubic-bezier(0.5, 0, 0.1, 1) both;
  animation-iteration-count: 1;
}
.textStyle_three {
  width: size-rem(400px);
  height: size-rem(125px);
  margin-left: size-rem(136px);
  animation: text-clip-three 2s 0.3s cubic-bezier(0.5, 0, 0.1, 1) both;
  animation-iteration-count: 1;
}
.textStyle_two {
  width: size-rem(720px);
  height: size-rem(125px);
  margin-left: size-rem(362px);
  animation: text-clip-two 2s 0.4s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.textStyleMob_one {
  height: size-rem(65px, true);
  animation: text-clip-mob 2s 0.1s cubic-bezier(0.5, 0, 0.1, 1) both;
  animation-iteration-count: 1;
}
.textStyleMob_three {
  height: size-rem(65px, true);
  animation: text-clip-mob 2s 0.3s cubic-bezier(0.5, 0, 0.1, 1) both;
  animation-iteration-count: 1;
}
.textStyleMob_two {
  height: size-rem(65px, true);

  animation: text-clip-mob 2s 0.4s cubic-bezier(0.5, 0, 0.1, 1) both;
}
.textStyleMob_four {
  height: size-rem(65px, true);
  animation: text-clip-mob 2s 0.4s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.textBox {
  position: absolute;
  top: 0;
  @include flex-column;
  width: 100%;
  height: 100%;
  @include vp--740 {
    align-items: center;
  }
}
.firstScreenSvgText {
  position: absolute;
  top: size-rem(463px);
  left: size-rem(329px);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.svgTextClass {
  width: size-rem(988.79px);
  height: size-rem(369.91px);
  animation: text-clip 2s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
}

@keyframes text-clip {
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    transform: translateY(50%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: translateY(0%);
  }
}

@keyframes text-clip-one {
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 5% 0%, 0% 0%);
    transform: translateY(80%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 4% 100%, 0% 54%);
    transform: translateY(0%);
  }
}

@keyframes text-clip-one-two {
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    transform: translateY(80%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: translateY(0%);
  }
}

@keyframes text-clip-two {
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 88% 0%, 49% 0%, 11% 0%, 0% 0%);
    transform: translateY(80%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 86% 70%, 53% 100%, 18% 100%, 0% 70%);
    transform: translateY(0%);
  }
}

@keyframes text-clip-three {
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 65% 0%, 0% 0%);
    transform: translateY(80%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 49%, 51% 100%, 0% 98%);
    transform: translateY(0%);
  }
}

@keyframes text-clip-mob {
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    transform: translateY(80%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: translateY(0%);
  }
}
