@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.parallaxContainer {
  height: auto;
  position: relative;
}
