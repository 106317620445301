@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: size-rem(50px);
  transition: 0.13s all ease-in;

  @include vp--740 {
    flex-direction: column;
    justify-content: center;
    margin-top: size-rem(60px, true);
  }

  &:hover div {
    transition: 0.13s all ease-in;
  }

  &:hover path {
    transition: 0.13s all ease-in;
  }

  & div {
    padding: size-rem(21px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    transition: 0.13s all ease-in;

    @include vp--740 {
      padding: size-rem(21px, true);
    }
  }

  & span {
    font-family: $main-font-family;
    font-size: size-rem(16px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
    margin-left: size-rem(20px);
    transition: 0.13s all ease-in;

    @include vp--740 {
      margin-left: 0;
      margin-top: size-rem(30px, true);
      font-size: size-rem(16px, true);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-transform: uppercase;
    }
  }

  &_black {
    &:hover div {
      background-color: $clr-black;
    }
    &:hover path {
      fill: $clr-white;
    }
    & div {
      border: 1px solid $clr-black;
    }
    & span {
      color: $clr-black;
    }
    & path {
      fill: $clr-black;
    }
  }
  &_white {
    &:hover div {
      background-color: $clr-white;
    }
    &:hover path {
      fill: $clr-black;
    }
    & div {
      border: 1px solid $clr-white;
    }
    & span {
      color: $clr-white;
    }
    & path {
      fill: $clr-white;
    }
  }
}
