@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.firstScreenContainer {
  width: 100%;
  max-height: size-rem(996px);
  min-height: size-rem(996px);
  height: size-rem(996px);
  position: relative;
  overflow: hidden;
  @include vp--740 {
    max-height: 100vh;
    min-height: 100vh;
    height: 100vh;
    width: 100%;
  }
}

.firstScreenBackground {
  background-image: url('../../../assets/images/bg_images/bgr_1_plug.jpg');
  background-size: 100%;
  background-position: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.firstScreenSvg {
  position: absolute;
  top: size-rem(102px);
  width: 100%;
  height: 100%;
  z-index: 5;
  @include vp--740 {
    top: size-rem(92px, true);
    left: size-rem(-25px, true);
    width: 100%;
    height: 100%;
  }
}

.svgClass {
  width: size-rem(1640.52px);
  height: size-rem(879px);
  @include vp--740 {
    width: size-rem(444px, true);
    height: size-rem(553px, true);
  }
}

.firstScreenSvgText {
  position: absolute;
  top: size-rem(463px);
  left: size-rem(329px);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.svgTextClass {
  width: size-rem(988.79px);
  height: size-rem(369.91px);
}

.firstScreenAnimText {
  position: absolute;
  top: size-rem(176px);
  left: size-rem(229px);
  width: 100%;
  height: 100%;
  z-index: 1;
  @include vp--740 {
    top: size-rem(220px, true);
    left: size-rem(0px, true);
  }
}

.firstScreenBoxText {
  @include flex-column;
  position: relative;
  height: size-rem(50px);
  bottom: size-rem(202px);
  justify-content: space-between;
  align-items: center;
  @include vp--740 {
    height: size-rem(45px, true);
    bottom: 20vh;
  }
}

.firstScreenTitle {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 120%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: $clr-white;
  @include vp--740 {
    font-size: size-rem(16px, true);
  }
}
.firstScreenText {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 120%;
  letter-spacing: -0.01em;
  color: $clr-light-grey;
  opacity: 0.8;
  @include vp--740 {
    font-size: size-rem(16px, true);
  }
}

.firstScreenGradient {
  position: absolute;
  width: size-rem(1252.79px);
  height: size-rem(720.1px);
  top: 30%;
  left: 10%;
  @include vp--740 {
    width: size-rem(633px, true);
    height: size-rem(269px, true);
    top: 70%;
    left: -10%;
  }
}
