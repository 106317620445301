@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.backgroundContainer {
  width: 100%;
  height: 100%;
  @include vp--740 {
    height: 100vh;
  }
}

.backgroundContainer img {
  max-width: 100%;
  min-width: 100%;
  @include vp--740 {
    min-width: 100%;
    max-width: 150%;
    min-height: 100vh;
    max-height: 100vh;
  }
}
